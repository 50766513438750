.merchant-custom-style iframe {
  padding: 0;
  margin: 0;
  height: 413px;
  z-index: 100000;
  margin-left: 80px;
  border: none;
  box-shadow: none;
}

.merchant-custom-style iframe > mat-sidenav-container {
  padding: 0;
}

.merchant-custom-style iframe > mat-sidenav-content {
  padding: 0;
}
